import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container } from "../../Shared";

const query = graphql`
  {
    data: islandJson {
      sectionThree {
        overline
        title
        discription
        paragraphs
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export function SectionThree() {
  const { data } = useStaticQuery(query);
  const { sectionThree } = data;
  const { overline, title, discription, paragraphs } = sectionThree;
  const image = getImage(sectionThree.image);

  return (
    <section>
      <Container>
        <div className="space-y-16 py-12 lg:py-20">
          <div className="flex flex-col lg:flex-row xl:items-center gap-12 lg:gap-20">
            <div className="lg:w-1/2 space-y-12">
              <div className="space-y-6">
                <span className="uppercase block font-mono text-overline tracking-[3px] text-sm">
                  {overline}
                </span>
                <h2 className="text-xl md:text-2xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
                  {title.split(" ")[0]}{" "}
                  <span className="text-red-500">{title.split(" ")[1]}</span>
                </h2>
                <p className="text-lg md:text-xl text-white">{discription}</p>
              </div>
              <div className="space-y-4">
                {Object.keys(paragraphs).map((key) => (
                  <p key={key} className="text-base md:text-lg text-white">
                    {paragraphs[key]}
                  </p>
                ))}
              </div>
            </div>
            <div className="lg:w-1/2 flex">
              <div className="relative flex mx-auto">
                <div className="absolute left-1/2 top-1/2 w-64 h-96 bg-red-500/50 blur-[14rem] rounded-[50%] -translate-x-1/2 -translate-y-1/2" />
                <GatsbyImage
                  image={image}
                  alt={title}
                  className="md:mx-auto md:max-w-[80%]"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
