import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container } from "../../Shared";

const query = graphql`
  {
    data: islandJson {
      sectionSeven {
        cards {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export function SectionSeven() {
  const { data } = useStaticQuery(query);
  const { sectionSeven } = data;
  const { cards } = sectionSeven;

  return (
    <section>
      <Container>
        <div className="space-y-16 py-12 lg:py-20">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {Object.keys(cards).map((key) => {
              const image = getImage(cards[key]);
              return (
                <div className="relative" key={key}>
                  <GatsbyImage image={image} alt="" className="w-full" />
                  <svg
                    className="absolute inset-0 translate-x-4 translate-y-4 -z-10"
                    viewBox="0 0 768 332"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M766 2H2V330H634.353L766 203.083V2Z"
                      stroke="#1F2023"
                      strokeWidth="4"
                    />
                  </svg>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
}
