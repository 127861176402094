import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container } from "../../Shared";

const query = graphql`
  {
    data: islandJson {
      sectionFive {
        title
        discription
        paragraphs
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export function SectionFive() {
  const { data } = useStaticQuery(query);
  const { sectionFive } = data;
  const { title, discription, paragraphs } = sectionFive;
  const image = getImage(sectionFive.image);

  return (
    <section>
      <Container>
        <div className="space-y-16 py-12 lg:py-20">
          <div className="flex flex-col lg:flex-row lg:items-center gap-12 lg:gap-20">
            <div className="lg:w-1/2 space-y-12">
              <div className="space-y-6">
                <h2 className="text-xl md:text-2xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
                  {title.split(" ").splice(0, 4).join(" ")}{" "}
                  <span className="text-red-500">{title.split(" ")[4]}</span>
                </h2>
                <p className="text-lg md:text-xl text-white">{discription}</p>
              </div>
              <div className="space-y-4">
                {Object.keys(paragraphs).map((key) => (
                  <p key={key} className="text-base md:text-lg text-white">
                    {paragraphs[key]}
                  </p>
                ))}
              </div>
            </div>
            <div className="lg:w-1/2 lg:flex">
              <div className="relative h-full">
                <GatsbyImage image={image} alt={title} className="lg:mx-auto" />
                <svg
                  className="absolute inset-0 translate-x-4 translate-y-4 -z-10"
                  viewBox="0 0 768 332"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M766 2H2V330H634.353L766 203.083V2Z"
                    stroke="#1F2023"
                    strokeWidth="4"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
