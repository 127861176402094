import * as React from "react";
import {
  HeroSection,
  SectionTwo,
  SectionThree,
  SectionFour,
  SectionFive,
  // SectionSix,
  SectionSeven,
} from "../components/Featured/Island";
import SEO from "../components/Featured/SEO";

function IndexPage() {
  return (
    <div className="overflow-x-hidden">
      <SEO />
      <HeroSection />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      {/* <SectionSix /> */}
      <SectionSeven />
    </div>
  );
}

export default IndexPage;
