import React from "react";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { graphql, useStaticQuery } from "gatsby";
import { Container } from "../../Shared";

const query = graphql`
  {
    data: islandJson {
      sectionOne {
        title
        description
        image {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              aspectRatio: 1.77
            )
          }
        }
      }
    }
  }
`;
export function HeroSection() {
  const { data } = useStaticQuery(query);
  const { sectionOne } = data;
  const { title, description, image } = sectionOne;
  const pluginImage = getImage(image);

  return (
    <section>
      <BgImage image={pluginImage} className="relative">
        <Container>
          <div className="relative flex flex-col justify-center pt-44 pb-20 xl:py-0 xl:h-[45rem]">
            <div className="space-y-6 text-center">
              <div className="flex flex-col items-center text-center space-y-4 mx-auto md:max-w-lg">
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold uppercase text-white font-serif tracking-widest leading-loose md:leading-loose lg:leading-loose xl:leading-loose 2xl:leading-loose">
                  {title}
                </h1>
                <p className="text-xl md:text-2xl text-white">{description}</p>
              </div>
              <div className="space-y-4">
                {/* <Button>Download</Button> */}
                <span className="uppercase block font-mono text-overline tracking-[3px] text-sm">
                  AVAILABLE Q2 2025
                </span>
              </div>
            </div>
          </div>
        </Container>
        <div className="absolute inset-0 bg-gradient-to-t from-main via-main/40 to-main -z-10" />
      </BgImage>
    </section>
  );
}
